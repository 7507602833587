export default () => ({
  hasSwitchedLanguage: sessionStorage.getItem('language-switched'),

  setLanguageSwitched() {
    sessionStorage.setItem('language-switched', 'yes');
  },

  init() {
    // Don't redirect automatically if language has already been switched
    if (this.hasSwitchedLanguage === 'yes') return;

    const languages = [...this.$root.querySelectorAll('a[hreflang]')].map(el => ({
      language: el.hreflang.split('-')[0],
      domain: el.dataset.domain,
      url: el.href,
      isActive: !!el.ariaCurrent,
    }));

    // Don't redirect if user directly accessed language
    // (only redirect automatically if frankstahl.com is visited without language in path)
    const hasDirectlyAccessedLanguage = languages.find(lang => lang.domain === window.location.pathname.split('/')[1]);
    if (hasDirectlyAccessedLanguage) return;

    // Get the browser language without the region code
    const browserLanguage = navigator.language.split('-')[0];

    // Find if the browser language exists site languages and is not active
    const matchingLanguage = languages.find(lang => lang.language === browserLanguage && !lang.isActive);

    // Redirect
    if (matchingLanguage) {
      this.setLanguageSwitched();
      window.location.href = matchingLanguage.url;
    }
  },
});
