import { animate, inView, stagger } from 'motion';
import { screens } from '@js/parts/tailwind-variables';

export default () => ({
  init() {
    const articles = this.$root.querySelectorAll('article');
    articles.forEach(article => {
      article.style.transform = 'translateY(25px)';
      article.style.opacity = 0;
    });

    inView(articles, (article) => {
      const articlePos = article.target.dataset.articlePos;
      let delay = 0;

      const breakpointSm = Number(screens.sm.slice(0, -2)); // Remove 'px' from string
      const breakpointLg = Number(screens.lg.slice(0, -2)); // Remove 'px' from string
      if (window.innerWidth >= breakpointSm) {
        delay = (articlePos % 2) * 0.2;
      }
      if (window.innerWidth >= breakpointLg) {
        delay = (articlePos % 3) * 0.2;
      }
      
      animate(article.target, { opacity: 1, transform: 'none' }, { delay: delay, duration: 1 });
    });
  },
});
