import { animate, stagger, timeline } from 'motion';

export default () => ({
  animationSequence: [],

  init() {
    this.animationSequence = [
      [this.$refs.image, { scaleY: 0.8, opacity: 0 }, { duration: 0 }],
      [this.$refs.content, { scaleX: 0 }, { duration: 0 }],
      [this.$refs.image, { scaleY: '1', opacity: 1 }, { duration: 0.8 }],
      [this.$refs.content, { scaleX: 1 }, { at: 0.4, duration: 0.3 }],
    ];
    this.$root.querySelectorAll('[data-animate="reveal-text"]').forEach(el => {
      const letters = el.textContent.trim().split('');
      const spans = [];
      el.textContent = '';
      letters.forEach(letter => {
        const span = document.createElement('span');
        span.style.opacity = 0;
        span.textContent = letter;

        // dirty fix to reduce size of ® on steelgreen page
        if (span.textContent === '®') {
          span.style.fontSize = '0.6em';
          span.style.verticalAlign = 'super';
        }
        spans.push(span);
        el.appendChild(span);
      });
      el.style.opacity = 1;
      this.animationSequence.push([spans, { opacity: 1 }, { delay: stagger(0.2 / spans.length), at: '-0.075' }]);
    });

    timeline(this.animationSequence, { defaultOptions: { easing: 'ease' } });
  },
});
