import { animate, stagger } from 'motion';

export default () => ({
  apiUrl: '',
  recaptchaSitekey: '',
  errorImageSrcset: '',
  localDomain: false,
  promptInput: '',
  isLoading: false,
  imagePath: '',
  imageSrcset: '',
  images: [],
  sliderInterval: undefined,
  visibleImage: 1,
  ownImageRequested: false,
  ownImageGenerated: false,
  canShare: false,
  loadingTexts: [],
  animationMoveAround: false,
  animationZoomIn: false,
  animationZoomOut: false,
  animationLoadingText: false,
  apiReturnedError: false,

  submit() {
    if (this.isLoading || this.promptInput.trim() === '') return;
    grecaptcha.ready(() => {
      grecaptcha.execute(this.recaptchaSitekey, { action: 'submit' }).then((token) => {
        this.requestImage(token);
      });
    });
  },

  async requestImage(recaptchaToken) {
    if (this.isLoading || this.promptInput.trim() === '') return;

    this.isLoading = true;
    this.ownImageRequested = true;
    clearInterval(this.sliderInterval);

    this.animationZoomIn = animate(this.images, {
      scale: [1.2, 10, 150],
      filter: 'grayscale(100%)',
    }, { duration: 2.5, easing: 'steps(12, end)' }).finished.then(() => {
      if (this.isLoading) {
        this.animationMoveAround = animate(this.images, {
          translateX: [-1500, 500, 1000, -500, -1000],
          translateY: [1500, 1000, -1000, 500, -500],
        }, { duration: 2, repeat: Infinity, easing: 'steps(5, start)' });
      }
    });

    this.animationLoadingText = animate(this.loadingTexts, { opacity: [0, 1, 1, 0] }, {
      offset: [0, 0.25, 0.75, 1],
      duration: 3,
      delay: stagger(3.2),
    });


    const orientation = screen.orientation.type.includes('portrait') ? 'PORTRAIT' : 'LANDSCAPE';

    let response = await fetch(this.apiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        'prompt': this.promptInput.toLowerCase().trim(),
        'orientation': orientation,
        'captchaToken': recaptchaToken,
      }),
    });


    if (response.ok) {
      let json = await response.json();

      const imagePaths = json.imagePaths.sort((a, b) => a.width - b.width);

      this.imageSrcset = imagePaths.map(path => {
        return `${this.localDomain ? 'https://test.frankstahl.com' : location.origin}/${path.imagePath} ${path.width}w`;
      }).join(', ');

      this.imagePath = (this.localDomain ? 'https://test.frankstahl.com' : location.origin) + '/' + imagePaths[imagePaths.length - 1].imagePath;

      this.finalizeGenerationProcess();
    } else if (!this.apiReturnedError) {
      this.isLoading = false;
      this.submit();
      this.apiReturnedError = true;
    } else {
      this.apiReturnedError = false;
      this.imageSrcset = this.errorImageSrcset;
      this.imagePath = this.errorImageSrcset.split(', ').pop().split(' ').shift();
      this.finalizeGenerationProcess();
    }

  },

  finalizeGenerationProcess() {
    this.ownImageGenerated = true;
    this.isLoading = false;

    if (this.animationLoadingText.playState === 'running') {
      this.animationLoadingText.cancel();
    }
    if (this.animationMoveAround.playState === 'running') {
      console.log('animationMoveAround stop');
      this.animationMoveAround.cancel();
    }

    this.animationZoomOut = animate(this.$refs.generatedImage, { scale: [150, 10, 1] }, {
      duration: 2.5,
      easing: 'steps(12, end)',
    });

    this.images = this.$refs.generatedImage;
  },

  share() {
    navigator.share({
      title: 'Stahl kann alles sein! by Frankstahl',
      text: `Hast du schon ${this.promptInput} aus Stahl gesehen?`,
      url: this.imagePath,
    });
  },


  fadeInOutImages() {
    this.images.forEach(image => image.style.opacity = 0);
    this.images[this.visibleImage % this.images.length].style.opacity = 100;
    this.visibleImage++;
  },

  init() {
    this.localDomain = this.$root.dataset.localDomain === 'yes';
    this.apiUrl = this.$root.dataset.apiUrl;
    this.recaptchaSitekey = this.$root.dataset.recaptchaSitekey;
    this.errorImageSrcset = this.$root.dataset.errorImageSrcset;
    this.loadingTexts = this.$refs.loadingTexts.querySelectorAll('div');
    this.images = this.$root.querySelectorAll('[data-headerimage]');
    this.canShare = !!navigator.share;

    this.sliderInterval = setInterval(() => {
      this.fadeInOutImages();
    }, 4000);

  },

  destroy() {
    clearInterval(this.sliderInterval);
  },
});
