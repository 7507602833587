import '@js/parts/css';
import '@js/parts/lazyloading';

/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import header from '@js/parts/header';
import pageHeader from '@js/parts/page-header';
import aiHeader from '@js/parts/ai-header';
import map from '@js/parts/map';
import companyTimeline from '@js/parts/timeline';

/* motion one */
import { animate, timeline } from 'motion';

/* barba.js */
import barba from '@barba/core';
import contentContainer from '@js/parts/content-container';
import frontpage from '@js/parts/frontpage';
import articleList from '@js/parts/article-list';
import companyPage from '@js/parts/company-page';
import languageSwitcher from '@js/parts/language-switcher';

barba.init({
  views: [{
    namespace: 'frontpage',
    afterEnter(data) {
      // Init frontpage js
      map();
    },
  }],
  transitions: [{
    name: 'opacity-transition',
    leave(data) {
      const sequence = [
        [data.current.container.querySelector('[x-data="pageHeader"] [x-ref="content"]'), { scaleX: 0 }],
        [data.current.container.querySelector('[x-data="pageHeader"] [x-ref="image"]'), {
          opacity: 0,
          scaleY: 1.2,
        }],
        [data.current.container.querySelectorAll('[x-data="contentContainer"], [x-data="companyPage"]'), {
          opacity: 0,
          transform: 'translateY(10%)',
        }, { at: '-0.1', duration: 0.4 }],
      ];
      return timeline(sequence, { duration: 0.5 }).finished.then(() => {
      });
    },
    enter(data) {
      window.scrollTo({ top: 0, left: 0 });

      // return animate(data.next.container, { opacity: 0 }).finished.then(() => {
      //   animate(data.next.container, { opacity: 1 }, { duration: 0 }).finished.then(() => {
      //   });
      // });
    },
    after(data) {
      if (data.next.url.hash) {
        const id = data.next.url.hash;
        const el = document.querySelector(`#${id}`);
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' });
        }
      }
    },
  }],
});

const barbaViewChangeBeforeLeaveEvent = new Event('barbaViewChangeBeforeLeave');
barba.hooks.beforeLeave((data) => {
  window.dispatchEvent(barbaViewChangeBeforeLeaveEvent);
});

document.addEventListener('alpine:init', () => {
  Alpine.store('modal', {
    content: '',
    isOpen: false,

    open(content) {
      this.content = content;
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
      this.content = '';
    },
  });
  Alpine.store('selectedCountryAccordion', 'AT');
});

Alpine.plugin(collapse);
Alpine.data('header', header);
Alpine.data('pageHeader', pageHeader);
Alpine.data('aiHeader', aiHeader);
Alpine.data('timeline', companyTimeline);
Alpine.data('contentContainer', contentContainer);
Alpine.data('frontpage', frontpage);
Alpine.data('companyPage', companyPage);
Alpine.data('articleList', articleList);
Alpine.data('languageSwitcher', languageSwitcher);
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR');
  });
}
