import jsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/maps/world-merc';
import '../maps/europe-merc';

export default function() {
  const map = new jsVectorMap({
    selector: '#map',
    showTooltip: false,
    map: 'europe-merc',
    draggable: false,
    zoomOnScroll: false,
    zoomMax: 4,
    zoomButtons: false,
    focusOn: {
      region: 'AT',
    },
    backgroundColor: '#EDEDED',
    regionStyle: {
      initial: {
        stroke: '#D8D8D8',
        strokeWidth: 0,
        fill: '#D8D8D8',
        fillOpacity: 1,
      },
      selected: {
        stroke: '#F9423A',
        strokeWidth: 0,
        fill: '#F9423A',
        fillOpacity: 1,
      },
    },
    markerStyle: {
      initial: {
        stroke: '#676767',
        strokeWidth: 2.5,
        fill: '#F9423A',
        fillOpacity: 1,
      },
      hover: {},
      selected: {
        fill: '#A5A5A5',
        stroke: '#FFFFFF',
        strokeOpacity: 1,
        fillOpacity: 1,
      },
      selectedHover: {},
    },
    onLoaded(map) {
      window.addEventListener('resize', () => {
        map.updateSize();
        map.setFocus(map.params.focusOn);
      });
    },
    onRegionClick(event, code) {
      if ([...Object.values(this._markers)].find(marker => marker.config.countryCode === code)) {
        expandCountryAccordion(code);
        this.setSingleSelectedCountry(code);
      }
    },
    onMarkerClick(event, markerIndex) {
      const code = this._markers[markerIndex].config.countryCode;
      expandCountryAccordion(code);
      this.setSingleSelectedCountry(code);
    },
  });

  if (typeof map.setSingleSelectedCountry !== 'function') {
    map.extend('setSingleSelectedCountry', function(countryCode) {
      const map = this;

      // Clear all selections
      map._clearSelected('regions');
      map._clearSelected('_markers');

      // Select region
      map._setSelected('regions', [countryCode]);

      // Select marker
      for (const [key, value] of Object.entries(map._markers)) {
        if (value.config.countryCode === countryCode) {
          map._markers[key].element.select(true);
        }
      }
    });
  }

  const countries = [];
  const countryButtonEls = document.querySelectorAll('[data-country-name]');

  countryButtonEls.forEach(el => {
    const name = el.dataset.countryName;
    const coords = [el.dataset.latitude, el.dataset.longitude];
    const countryCode = el.dataset.countryCode;

    countries.push({ name, coords, countryCode });

    // add eventListener to select marker on map when clicked
    el.addEventListener('click', () => map.setSingleSelectedCountry(countryCode));
  });

  map.addMarkers(countries);
  map.setSingleSelectedCountry('AT');
}

function expandCountryAccordion(countryCode) {
  Alpine.store('selectedCountryAccordion', countryCode);
}
