import { animate, inView } from 'motion';

export default () => ({
  init() {
    this.$root.style.opacity = 0;
    this.$root.style.transform = 'translateY(30px)';

    inView(this.$root, () => {
      animate(this.$root, { opacity: 1, transform: 'none' }, { delay: 0.7, duration: 0.65 });
    });
  },
});
