import { animate, inView } from 'motion';

export default () => ({
  imageSrc: '',
  imageVisible: false,

  init() {
    const timeEntries = this.$root.querySelectorAll('[data-time-entry]');

    timeEntries.forEach(entry => {
      entry.style.opacity = 0;
      entry.style.transform = 'translateY(40px)';
    });

    inView(timeEntries, ({ target }) => {
      animate(target, { opacity: 1, transform: 'translate(var(--tw-translate-x), 0)' }, { duration: 0.5 });
    }, { amount: 0.5 });
  },
});
