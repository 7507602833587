import { animate, inView } from 'motion';
import Atropos from 'atropos';

export default () => ({
  companyVideoPlayButtonAtropos: false,

  init() {
    this.companyVideoPlayButtonAtropos = Atropos({
      el: '.atropos-company',
      activeOffset: 10,
      shadowScale: 0,
    });

    const animateEls = this.$root.querySelectorAll('[data-animate]');

    animateEls.forEach(el => {
      el.style.opacity = 0;
      el.style.transform = 'translateY(30px)';
      inView(el, (element) => {
        animate(element.target, { opacity: 1, transform: 'none' }, { duration: 0.65 });
      });
    });
  },

  destroy() {
    this.companyVideoPlayButtonAtropos.destroy();
  },
});
