import Alpine from 'alpinejs';
import { screens } from '@js/parts/tailwind-variables';

export default () => ({
  navIsOpen: false,
  offsetTop: window.scrollY,
  navIsMinimized: false,
  navAtTop: true,
  currentSlug: '',

  toggleMobileNav() {
    if (this.navIsOpen) {
      this.closeNav();
    } else {
      this.openNav();
    }
  },

  openNav() {
    this.navIsOpen = true;
    document.body.classList.add('overflow-hidden');
    document.body.classList.add('h-screen');
  },

  closeNav() {
    document.body.classList.remove('overflow-hidden');
    document.body.classList.remove('h-screen');
    this.navIsOpen = false;
  },

  scrolling() {
    this.scrollingDown = this.offsetTop < window.scrollY;
    this.offsetTop = window.scrollY;
    this.navAtTop = this.offsetTop < 250;
    this.navIsMinimized = this.scrollingDown && this.offsetTop > 50;
  },

  setCurrentMenuItem() {
    const { pathname } = window.location;
    const pathParts = pathname.split('/');
    this.currentSlug = pathParts.pop();
  },

  init() {
    this.setCurrentMenuItem();
    window.addEventListener(
      'resize',
      Alpine.debounce(() => {
        const breakpoint = Number(screens.xl.slice(0, -2)); // Remove 'px' from string
        if (window.innerWidth >= breakpoint && this.navIsOpen) {
          this.closeNav();
        }
      }, 100),
    );

    window.addEventListener('barbaViewChangeBeforeLeave', () => {
      this.setCurrentMenuItem();
    });
  },
});
