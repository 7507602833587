import { animate, inView } from 'motion';
import Atropos from 'atropos';

export default () => ({
  companyVideoPlayButtonAtropos: false,
  init() {
    const sections = this.$root.querySelectorAll('section');
    this.companyVideoPlayButtonAtropos = Atropos({
      el: '.atropos',
      activeOffset: 10,
      shadowScale: 0,
    });

    sections.forEach(section => {
      section.style.opacity = 0;
      section.style.transform = 'translateY(60px)';
    });

    inView(sections, ({ target }) => {
      animate(target, { opacity: 1, transform: 'none' }, { duration: 0.5 });
    }, { amount: 0.5 });
  },

  destroy() {
    this.companyVideoPlayButtonAtropos.destroy();
  },
});
